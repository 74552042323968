import { forwardRef, useEffect, useRef } from 'react';

interface ITextInput {
  type?: string;
  className?: string;
  isFocused?: boolean;

  [key: string]: any; // for additional props like 'placeholder', 'value', etc.
}

const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  ({ type = 'text', className = '', isFocused = false, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const effectiveRef = ref || inputRef;

    useEffect(() => {
      // Type guard to check if effectiveRef is a ref object
      if (isFocused && 'current' in effectiveRef && effectiveRef.current) {
        effectiveRef.current.focus();
      }
    }, [isFocused, effectiveRef]);

    return (
      <div className="flex flex-col items-start">
        <input
          {...props}
          type={type}
          className={`input ${className}`} // Using template literals for className
          ref={effectiveRef}
        />
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
